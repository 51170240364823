import React from "react";
import { Link } from "react-router-dom";
import FooterBG from "../../assets/pngs/footer-bg.png";
import Logo from "../../assets/pngs/header-logo.png";
import { footerLinks, socials } from "../../utils/data";

const Footer = () => {
  return (
    <div className="bg-dt-dark relative">
      <img
        src={FooterBG}
        className="absolute bottom-0 right-0 h-2/3 sm:h-[80%]"
        alt="footer-ill"
      />
      <p className="absolute bottom-[1rem] text-nowrap text-dt-section-description-sm text-white opacity-30 z-50 left-[50%] translate-x-[-50%]">
        © 2025 DevTrove. All Rights Reserved.
      </p>
      <div className="container mx-auto px-8 xl:px-20 py-16 flex items-start justify-between flex-col sm:flex-row">
        <div className="flex basis-1/2 flex-col items-start justify-start gap-4">
          <img alt="footer-logo" src={Logo} className="h-[55px]" />
          <div className="flex flex-row items-center gap-4 justify-start">
            {socials.map((social, index) => (
              <div key={index} className="social-icon" aria-label={social.alt}>
                <Link to={social.link} target="_black" rel="noreferrer">
                  {social.icon}
                </Link>
              </div>
            ))}
          </div>
          <p className="text-dt-section-description-sm mt-12 text-white">
            DevTrove is a premier IT service & solutions company, dedicated to
            providing advanced, innovative, and tailored digital solutions that
            meet client needs.
          </p>
        </div>
        <div className="flex flex-row sm:flex-col gap-4 flex-wrap sm:mt-0 mt-6 items-start justify-center basis-1/3">
          {footerLinks.map((link, index) => (
            <Link
              key={index}
              to={link.link}
              className="hover:text-white text-nowrap hover:opacity-100 text-dt-section-description text-dt-light opacity-75"
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
