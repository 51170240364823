import axios from "axios";
import { BE_URL } from "../beURL";

export async function sendContactForm(formData) {
  try {
    const response = await axios.post(`${BE_URL}/devtrove/contact`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      "Error sending contact form:",
      error.response?.data || error.message
    );
    throw error;
  }
}
